/* App.css */

.App {
  font-family: Arial, sans-serif;
  color: #333;
}

/* 首屏样式 */
.first-screen {
  height: 1000px;
  background-image: url('../public/bg2.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

/* 导航区样式 */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center; /* 使logo图片和导航文字垂直居中对齐 */
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  position: relative;
  z-index: 10;
}

.logo img {
  height: 50px; /* 可根据需要调整图片大小 */
}

.nav-links {
  margin-right: 80px;
}

.nav-links a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
  font-size: 18px; /* 增大字体大小 */
  font-weight: bold; /* 让文字更醒目 */
}


/* 内容区样式 */
.first-screen-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  gap: 10px; /* 调整文字之间的间距 */
  position: relative;
  top: -100px;
}

.first-screen-content h1 {
  font-size: 180px;
  margin: 0; /* 去除默认外边距 */
}

.first-screen-content p {
  font-size: 50px;
  margin: 0; /* 去除默认外边距 */
}

/* 第二屏样式 */
/* 第二屏整体样式 */
.second-screen {
  height: 950px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
}

/* 内容区，除去脚标区 */
.content-area {
  flex: 1;
  display: flex;
  padding: 40px;
}

/* 脚标区 */
.footer {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999;
  background-color: #f0f0f0;
}

/* app.css */
.footer a {
  color: #999; /* 或者其他颜色 */
  text-decoration: none; /* 移除默认下划线 */
}

.footer a:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
}


/* 信息区，占左侧40% */
.info-section {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 100px; /* 距离屏幕左侧100px */
  padding-right: 20px; /* 右边距以便与图片区隔开 */
}

/* 信息区上部主题区 */
.info-section .title {
  font-size: 80px;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 80px;
  color:#666;
}


.details-section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 100px; /* 增加每段信息之间的间距 */
  position: relative;
  left:50px;
}

.detail-item {
  font-size: 40px; /* 标题字体大小 */
}

.detail-info {
  font-size: 30px; /* 详细信息字体大小 */
  font-weight: normal;
  color: #888787;
  margin-top: 3px; /* 细化标题和内容之间的间距 */
}


/* 图片区，占右侧60% */
.image-section {
  flex: 0 0 60%;
  display: flex;
  justify-content: space-evenly; /* 图片水平布局，均匀分布 */
  align-items: center;
  gap: 20px;
}

/* 图片样式 */
.image-section img {
  width: 45%; /* 放大图片至占图片区的45%宽度，确保大部分空白区域被填充 */
  height: auto; /* 保持图片的宽高比 */
  border-radius: 10px; /* 圆角效果 */
  object-fit: cover;
}

/* 左侧图片偏移 */
.image-section img:first-child {
  width: 400px;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  transform: translateY(100px); /* 向下偏移 50px */
}

/* 右侧图片偏移 */
.image-section img:last-child {
  width: 400px;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  transform: translateY(-100px); /* 向上偏移 50px */
}

